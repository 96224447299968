import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledWidgetsHero = styled.section`
	color: ${CSSVars.white};
	overflow: hidden;
	padding: 0px;
	background: ${CSSVars.black};
	position: relative;
	padding: 0 0 230px;

	.hero-wrapper {
		position: relative;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		padding: 20px 20px 0;

		.main-content {
			position: relative;

			.glass {
				pointer-events: none;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		h1 {
			font-size: 400px;
			font-weight: 600;
			background: linear-gradient(
				270deg,
				rgba(255, 255, 255, 0.8) 0%,
				rgba(182, 198, 255, 0.5) 100%
			);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			@media screen and (max-width: ${CSSVars.breakpointLG}) {
				font-size: 300px;
			}

			@media screen and (max-width: ${CSSVars.breakpointMD}) {
				font-size: 200px;
			}

			@media screen and (max-width: ${CSSVars.breakpointSM}) {
				font-size: 150px;
			}
		}

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			padding: 20px 40px 0;
		}
	}
`;
