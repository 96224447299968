'use client';

import React from 'react';
import { StyledWidgetsHero } from './notFound.styles';
import Image from 'next/image';
import { Header } from '../header/header.comp';

export const NotFound = () => {
    return (
        <StyledWidgetsHero>
            <Header style={{ zIndex: '1', position: 'relative' }} />
            <div className="hero-wrapper">
                <div className="main-content">
                    <h1>
                        404
                    </h1>
                    <Image
                        src="/assets/404/glass.png"
                        alt="Glass"
                        className="glass"
                        width={1971}
                        height={1315}
                    />
                </div>
            </div>
        </StyledWidgetsHero>
    );
};
